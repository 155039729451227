import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Slider from 'react-slick';
import Img, { FluidObject } from 'gatsby-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductDetailCarousel.css';

import { white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';

interface Props {
  carouselImages: {
    id: string;
    fluid: FluidObject;
  }[];
}

const ProductDetailCarousel = ({ carouselImages }: Props) => {
  const settings = {
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul className={css(styles.ul)}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <button className={css(styles.carouselButton)} aria-hidden />
    ),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className={css(styles.container)}>
      <Slider
        accessibility={true}
        focusOnSelect={true}
        infinite={true}
        speed={500}
        slidesToShow={3}
        centerMode={true}
        arrows={false}
        dots={false}
        swipe={true}
        autoplay
        autoplaySpeed={5000}
        {...settings}
      >
        {carouselImages.map(({ fluid, id }) => (
          <img key={id} src={fluid.src} className={css(styles.img)} />
        ))}
      </Slider>
    </div>
  );
};

export default ProductDetailCarousel;

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    height: 550,
    borderTop: `1px solid ${white}`,
  },
  img: {
    position: 'relative',
    height: 480,
    objectFit: 'cover',
    marginBottom: -7,
  },
  carouselButton: {
    // width: 100,
    // height: 50,
    // borderTop: `4px solid ${white}`,
    // [BREAKPOINTS.TABLET]: {
    //   width: 50,
    // },
  },
  ul: {
    margin: 0,
  },
});
