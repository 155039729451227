import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { StyleSheet, css } from 'aphrodite';

import SEO from 'components/SEO/SEO';
import ShopFlavorTitle from 'components/ShopFlavorTitle/ShopFlavorTitle';
import ProductCard from 'components/ProductCard/ProductCard';
import ProductDetailCarousel from 'components/ProductDetailCarousel/ProductDetailCarousel';
import TippyTooltip from 'components/TippyTooltip/TippyTooltip';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import { convertToVW } from 'shared/utils';

interface Props {
  data: {
    contentfulFlavorObject: {
      name: string;
      description: string;
      slug: string;
      products: {
        id: string;
        name: string;
        imageAltText: string;
        image: {
          fluid: FluidObject;
        };
        productVariants: {
          id: string;
          name: string;
          potency: 'Light' | 'Medium' | 'Heavy';
          potencyValue: string;
          price: string;
        }[];
        threedmodel: string;
        usethreedModelAsRelatedProductThumb: boolean;
        useVideoOnHoverAsRelatedProduct?: boolean;
        backgroundVideo?: {
          file: {
            url: string;
          };
        };
      }[];
      calivaLink: string;
    };
    contentfulProduct: {
      name: string;
      imageAltText: string;
      image: {
        fluid: FluidObject;
      };
      productVariants: {
        id: string;
        potency: string;
        potencyValue: string;
      }[];
      carouselImages: {
        id: string;
        fluid: FluidObject;
      }[];
      calivaLink: string;
      threedmodel: string;
      useThreedModelProductPage: boolean;
    };
    contentfulProductVariant: {
      id: string;
      sku: string;
      potencyDescription: {
        potencyDescription: string;
      };
      price: string;
    };
  };
  location: {
    pathname: string;
  };
}

const ProductDetailPage = ({
  data: {
    contentfulProductVariant: {
      id: variantId,
      sku,
      potencyDescription: { potencyDescription },
      price,
    },
    contentfulFlavorObject: { name: flavorName, description, products, slug },
    contentfulProduct: {
      name: productName,
      imageAltText,
      image: { fluid },
      productVariants,
      carouselImages,
      calivaLink,
      threedmodel,
      useThreedModelProductPage,
    },
  },
  location: { pathname },
}: Props) => {
  // Set the currently selected potency to state, using location.pathname
  const [checked] = useState<string>(
    pathname.slice(pathname.lastIndexOf('/') + 1)
  );

  // Create array of available potencies for this product
  const availablePotencies = productVariants.map(
    ({ id, potency, potencyValue }) => {
      return {
        id,
        potency,
        potencyValue,
      };
    }
  );

  // Strip url of current potency, to be used to build links to other available
  // potencies
  const strippedPath = pathname.substring(0, pathname.lastIndexOf('/'));

  // Create array of other products for this flavor, excluding the currently
  // displayed product variant
  const filteredProducts = products.map((product) => {
    const productVariants = product.productVariants.filter(
      (variant) => variant.id !== variantId
    );
    return { ...product, productVariants };
  });

  return (
    <main className={css(styles.container)}>
      <SEO
        title={productName}
        //? If we wanted, we could add a description field to individual products
        //? (or product variants, whichever) in the CMS and pull that description
        //? into the SEO component here
        pathname={pathname}
      />
      {useThreedModelProductPage && threedmodel ? (
        <div className={css(styles.threedmodelContainer)}>
          <iframe
            className={css(styles.threedmodel)}
            src={threedmodel}
            frameBorder="0"
          />
        </div>
      ) : (
        <Img
          fluid={fluid}
          className={css(styles.mainProductImage)}
          alt={imageAltText}
        />
      )}
      <div className={css(styles.mainProductText)}>
        <h1 className={css(styles.h1)}>{productName}</h1>
        <p className={css(styles.flavorName)}>{flavorName}</p>
        <ul className={css(styles.ul)}>
          {availablePotencies.map(({ potency, id, potencyValue }) => (
            <li key={id} className={css(styles.li)}>
              <TippyTooltip>
                <Link
                  to={`${strippedPath}/${potency.toLowerCase()}`}
                  className={css(styles.link)}
                >
                  <label htmlFor={potency} className={css(styles.label)}>
                    {potency} -{' '}
                    <span className={css(styles.potencyValue)}>
                      {potencyValue}%
                    </span>
                  </label>
                </Link>
              </TippyTooltip>
            </li>
          ))}
        </ul>
        <p className={css(styles.description)}>{potencyDescription}</p>
        <a href={calivaLink} target="_blank">
          <button className={css(styles.button)}>
            <span className={css(styles.buttonText)}>
              <span className={css(styles.buttonPrice)}>{price}</span> SHOP NOW
            </span>
          </button>
        </a>
      </div>
      <div className={css(styles.carouselContainer)}>
        <ProductDetailCarousel carouselImages={carouselImages} />
      </div>
      {/* We only want to show this section if there are multiple products
      for this flavor */}
      {filteredProducts.length > 1 && (
        <div className={css(styles.packagingChoicesContainer)}>
          <ShopFlavorTitle name={flavorName} description={description} />
          <div className={css(styles.flexContainer)}>
            {filteredProducts.map(
              (
                {
                  id,
                  image: { fluid },
                  imageAltText,
                  productVariants,
                  name: packagingName,
                  threedmodel,
                  usethreedModelAsRelatedProductThumb,
                  useVideoOnHoverAsRelatedProduct,
                  backgroundVideo,
                },
                index
              ) => {
                return (
                  <ProductCard
                    key={id}
                    fluid={fluid}
                    id={id}
                    imageAltText={imageAltText}
                    index={index}
                    isSingle={filteredProducts.length === 2 ? true : false}
                    productVariants={productVariants}
                    slug={slug}
                    productName={productName}
                    packagingName={packagingName && packagingName}
                    isPackagingChoices
                    threedmodel={threedmodel}
                    useThreedModelAsThumb={usethreedModelAsRelatedProductThumb}
                    useVideoOnHoverAsThumbnail={useVideoOnHoverAsRelatedProduct}
                    backgroundVideo={backgroundVideo}
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </main>
  );
};

export default ProductDetailPage;

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    backgroundColor: black,
    color: white,
    width: '100%',
    paddingTop: '120px',
    gridTemplateColumns: 'repeat(2, 50%)',
    gridTemplateRows: `${convertToVW(1130)} 673px 1fr`,
    [BREAKPOINTS.TABLET]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '90px',
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '4rem',
    },
  },
  mainProductImage: {
    width: '50%',
    marginLeft: '50vw',
    maxHeight: 700,
    height: 700,
    borderLeft: `1px solid ${white}`,
    [BREAKPOINTS.SATURN_FIX]: {
      width: '100%',
      marginLeft: '0vw',
      height: 500,
      borderLeft: `0px solid ${white}`,
      borderBottom: `1px solid ${white}`,
    },
  },
  mainProductText: {
    position: 'absolute',
    top: '120px',
    width: '50vw',
    padding: '0 8vw',
    placeSelf: 'start center',
    [BREAKPOINTS.SATURN_FIX]: {
      position: 'relative',
      top: '0px',
      width: '100vw',
      padding: '20px',
    },
  },
  h1: {
    fontFamily: 'Titling Gothic FB Compressed Standard',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: '50px',
    marginTop: '6rem',
    lineHeight: 1,
    [BREAKPOINTS.SATURN_FIX]: {
      marginTop: '20px',
    },
  },
  flavorName: {
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    fontSize: '18px',
    lineHeight: 1.44,
    [BREAKPOINTS.TABLET]: {
      padding: '1rem 0 1.5rem',
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '1rem',
      paddingBottom: '2rem',
    },
  },
  ul: {
    display: 'flex',
    margin: 0,
    [BREAKPOINTS.TABLET]: {
      flexDirection: 'column',
    },
  },
  li: {
    listStyleType: 'none',
    [BREAKPOINTS.TABLET]: {
      padding: '0 0 .3rem',
    },
  },
  link: {
    textDecoration: 'none',
  },
  label: {
    textTransform: 'uppercase',
    color: white,
    marginRight: '0.75rem',
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
    [BREAKPOINTS.TABLET]: {},
  },
  input: {
    marginRight: '0.5rem',
    [BREAKPOINTS.TABLET]: {
      verticalAlign: 'middle',
    },
  },
  potencyValue: {
    fontSize: '12px',
    verticalAlign: 'middle',
  },
  description: {
    fontSize: '12px',
    lineHeight: 1.5,

    [BREAKPOINTS.TABLET]: {},
    [BREAKPOINTS.MOBILE]: {},
  },
  packagingChoicesContainer: {
    width: '100%',
    gridColumn: '1/-1',
    gridRow: 3,
  },
  flexContainer: {
    backgroundColor: black,
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 50%',
    [BREAKPOINTS.TABLET]: {
      display: 'block',
    },
  },
  carouselContainer: {
    gridRow: 2,
    gridColumn: '1 / 3',
    [BREAKPOINTS.SATURN_FIX]: {
      width: '99vw',
    },
  },
  iframe: {
    margin: '100px 100px 0 0',
    height: 80,
    [BREAKPOINTS.SATURN_FIX]: {
      margin: '50px 0 0',
      width: '100%',
    },
  },
  button: {
    lineHeight: 1.2,
    letterSpacing: 1.62,
    textTransform: 'uppercase',
    width: '100%',
    height: 70,
    backgroundColor: 'transparent',
    color: white,
    padding: 0,
    marginTop: convertToVW(55),
    border: `1px solid ${white}`,
    ':focus': {
      border: `4px solid ${white}`,
    },
    ':hover': {
      backgroundColor: white,
      transition: 'background 0.3s ease-in-out',
    },
    ':hover > span': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },
    [BREAKPOINTS.MOBILE]: {
      marginTop: '2rem',
    },
  },
  buttonText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 1.62,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Wide',
  },

  buttonPrice: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    marginRight: '20px',
  },

  threedmodelContainer: {
    width: 'calc(50% - 7px)',
    marginLeft: '50vw',
    marginBottom: 0,
    maxHeight: 700,
    height: 700,
    [BREAKPOINTS.SATURN_FIX]: {
      width: '100%',
      marginLeft: '0vw',
      height: 500,
    },
  },
  threedmodel: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    background: 'none',
  },
});

export const query = graphql`
  query(
    $flavorSlug: String!
    $productId: String!
    $productName: String!
    $productVariantId: String!
    $calivaLink: String
  ) {
    contentfulFlavorObject(slug: { eq: $flavorSlug }) {
      name
      description
      slug
      products {
        id
        name
        imageAltText
        image {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        calivaLink
        productVariants {
          id
          name
          potency
          potencyValue
          price
        }
        threedmodel
        usethreedModelAsRelatedProductThumb
        useVideoOnHoverAsRelatedProduct
        backgroundVideo {
          file {
            url
          }
        }
      }
    }
    contentfulProduct(
      name: { eq: $productName }
      id: { eq: $productId }
      calivaLink: { eq: $calivaLink }
    ) {
      name
      imageAltText
      image {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      calivaLink
      threedmodel
      useThreedModelProductPage
      usethreedModelAsRelatedProductThumb
      useVideoOnHoverAsRelatedProduct
      backgroundVideo {
        file {
          url
        }
      }
      productVariants {
        id
        potency
        potencyValue
      }
      carouselImages {
        id
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulProductVariant(id: { eq: $productVariantId }) {
      id
      sku
      potencyDescription {
        potencyDescription
      }
      price
    }
  }
`;
